import React, { useContext } from 'react'
import { Button } from 'moar-interactive-shared'
import ModalContext from './../Context/modal'

const InfoPopup = (props) => {
  const { text, cta_text, detail_page_id } = props
  const { setShowMediaModal, setModalData } = useContext(ModalContext)
  return (
    <div className="tooltip infoPopup">
      {text ? <p>{text}</p> : null}
      <Button
        label={cta_text}
        handleClick={() => {
          setModalData(detail_page_id)
          setShowMediaModal(true)
        }}
      />
    </div>
  )
}

export default InfoPopup
