import anime from 'animejs/lib/anime.es.js'

const slideAnimation = (
  text,
  image,
  hotspots,
  beginFunction,
  endFunction,
  windowWidth,
) => {
  const hotspotElems = [...hotspots.current.querySelectorAll('.hotspot')]

  const animeTimeline = anime.timeline({
    autoplay: false,
    begin: () => {
      if (beginFunction()) {
        beginFunction()
      }
    },
    complete: () => {
      if (endFunction) {
        endFunction()
      }
    },
  })

  if (windowWidth < 1024) {
    animeTimeline
      .add(
        {
          easing: 'easeInQuint',
          targets: image.current,
          opacity: [0, 1],
          duration: 1000,
        },
        0,
      )
      .add(
        {
          easing: 'easeOutQuint',
          targets: text.current,
          translateY: [50, 0],
          opacity: [0, 1],
        },
        600,
      )
      .add(
        {
          easing: 'easeOutElastic(1, .6)',
          targets: hotspotElems,
          opacity: [0, 1],
          translateY: [40, 0],
          delay: anime.stagger(200),
          scale: [0.8, 1],
        },
        1000,
      )
  } else {
    const imageTranslate = [-200, 0];

    animeTimeline
      .add(
        {
          easing: 'easeInQuint',
          targets: image.current,
          opacity: [0.1, 1],
          duration: 2400,
          scale: [1.4, 1],
          translateX: imageTranslate,
        },
        0,
      )
      .add(
        {
          easing: 'easeOutQuint',
          targets: text.current,
          translateX: [-20, 0],
          opacity: [0, 1],
        },
        2300,
      )
      .add(
        {
          easing: 'easeOutElastic(1, .6)',
          targets: hotspotElems,
          opacity: [0, 1],
          translateY: [40, 0],
          delay: anime.stagger(200),
          scale: [0.8, 1],
        },
        3100,
      )
  }

  return animeTimeline
}

export default slideAnimation
