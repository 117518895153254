import React, { useRef, useEffect } from 'react'
import { Button } from 'moar-interactive-shared'
import { fadeIn, fadeOut } from './../animations/overlayAnimation'
import renderHTML from 'react-render-html'

const Intro = (props) => {
  const { goToSelect, setIntroIsAnimating, data } = props
  const textRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    setIntroIsAnimating(true)
    fadeIn(textRef, buttonRef, () => setIntroIsAnimating(false))
  }, []) /* eslint-disable-line */

  const leave = () => {
    props.setIntroIsAnimating(true)
    fadeOut(textRef, buttonRef, () => {
      goToSelect()
    })
  }

  return (
    <div className="page page--intro page--frame">
      <div className="page__wrapper">
        <div className="panel">
          <div className="panel__inner">
            <div ref={textRef}>
              {data.intro_text ? renderHTML(data.intro_text) : null}
            </div>

            <div className="button-container">
              <Button
                ref={buttonRef}
                label={data.intro_cta_text}
                handleClick={() => {
                  if (props.introIsAnimating) {
                    return
                  }
                  leave()
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="introImage">
        <img
          alt=""
          className="introImage__mobile"
          src={data.intro_image_mobile}
        />

        <img
          alt=""
          className="introImage__desktop"
          src={data.intro_image}
        />
      </div>
    </div>
  )
}

export default Intro
