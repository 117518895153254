import React from 'react';

const ShareIcon = () => {
    return (
        <svg width="16px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0361 10.4819C10.7367 10.4819 10.4584 10.559 10.2139 10.6916C9.98314 10.8169 9.70182 10.856 9.47471 10.7241L5.56023 8.44578C5.59035 8.30723 5.61446 8.16868 5.61446 8.0241C5.61446 7.87952 5.59035 7.74097 5.56023 7.60241L9.42169 5.35121C9.65061 5.21747 9.93555 5.25904 10.1669 5.38855C10.4247 5.53193 10.7199 5.61446 11.0361 5.61446C12.0361 5.61446 12.8434 4.80723 12.8434 3.80723C12.8434 2.80723 12.0361 2 11.0361 2C10.0361 2 9.22892 2.80723 9.22892 3.80723C9.22892 4.06145 9.13434 4.31566 8.91506 4.44337L5.42168 6.48012C5.19277 6.61385 4.90783 6.57229 4.6765 6.44277C4.41867 6.2994 4.12349 6.21687 3.80723 6.21687C2.80723 6.21687 2 7.0241 2 8.0241C2 9.0241 2.80723 9.83133 3.80723 9.83133C4.12349 9.83133 4.41808 9.7488 4.67591 9.60542C4.90784 9.47651 5.19277 9.43494 5.42168 9.56868L8.98375 11.65C9.18676 11.7687 9.27712 12.006 9.27712 12.241C9.27712 13.2108 10.0663 14 11.0361 14C12.006 14 12.7952 13.2108 12.7952 12.241C12.7952 11.2711 12.006 10.4819 11.0361 10.4819Z"
                fill="#1A1A1A"
            />
        </svg>
    );
}

export default ShareIcon;
