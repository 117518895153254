import { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom'

const slug = document.querySelector('#root').getAttribute('data-slug')

const useInitRoute = (data, setStoryData, goToStep) => {
  const params = useParams();
  const glossaryMatch = useRouteMatch(`/interactives/${slug}/app/glossary`);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (params.person) {
      const selectedData = data.stories.data.find(
        (personData) => params.person === personData.name_id,
      );

      if (selectedData) {
        setStoryData(selectedData)
        goToStep('slides')
      } else {
        goToStep('intro')
      }
    } else if (glossaryMatch?.isExact) {
      goToStep('glossary');
    } else {
      setStoryData(data.stories.data[0]);
      goToStep('intro');
    }
  }, [data]) /* eslint-disable-line */
}

export default useInitRoute
