import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { Untabbable } from 'react-untabbable'
import styled from 'styled-components';

// components
import AudioPlayer from './AudioPlayer'
import InfoPopup from './InfoPopup';
import Hotspot from './../components/Hotspot'
import TextModule from './../components/TextModule'
import { ReactComponent as NextIcon } from './../Icons/icon-next-arrow.svg'

// functionality
import slideAnimation from './../animations/slideAnimation'
import useWindowSize from '../hooks/useWindowSize'
import isMobile from '../utils/isMobile'

const LearnMoreHrStyled = styled.hr`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const LearnMoreHeadingStyled = styled.h2`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const LearnMoreHotspotContainerStyled = styled.div`
  &:not(:last-child) {
      margin-bottom: 1rem;
  }
`;

const InfoSlide = ({
  setSlideIsAnimating,
  isActive,
  pageReady,
  body,
  audio,
  image,
  image_alt_text,
  image_position,
  mobile_image,
  hotspots,
  mobile_hotspots,
  show_continue_cta,
  appMeta,
  nextSlide,
}) => {
  const windowSize = useWindowSize()
  const slideRef = useRef()
  const hotspotRef = useRef()
  const textRef = useRef()
  const imageRef = useRef()
  const [timeline, setTimeline] = useState()
  const [hasAnimated, setHasAnimated] = useState(false)

  const beginFunction = () => {
    setSlideIsAnimating(true)
  }

  const endFunction = () => {
    setSlideIsAnimating(false)
    setHasAnimated(true)
  }

  // set up animation on mount
  useLayoutEffect(() => {
    if (isActive === true && hasAnimated === false) {
      setTimeline(
        slideAnimation(
          textRef,
          imageRef,
          hotspotRef,
          beginFunction,
          endFunction,
          windowSize.width,
        ),
      )
    }
  }, [isActive]) /* eslint-disable-line */

  // test default
  let hotspotItems = [{
    customIcon: false,
    x: 50,
    y: 50,
    text:
      'this is dummy data this will go away when hotspots are added correctly',
    cta_text: 'cta link to page',
    detail_page_id: 1,
  }]

  let hotspotImage = `http://moar-staging.imgix.net/6692c114-d3a5-4986-812e-dc4ced0aecc0/bg.jpg?auto=compress%2Cformat&fit=min&fm=jpg&q=80&rect=%2C%2C%2C`
  if (hotspots) {
    hotspotImage = hotspots.image
    hotspotItems = hotspots.items
  }

  // trigger animation when active and timeline ready
  // and had not animated yet
  useEffect(() => {
    if (isActive && timeline && !hasAnimated && pageReady) {
      timeline.play()
    }
  }, [isActive, timeline, hasAnimated, pageReady])

  if (isMobile()) {
    hotspotImage = mobile_hotspots.image
    hotspotItems = mobile_hotspots.items
  } else {
    hotspotImage = hotspots.image
    hotspotItems = hotspots.items
  }

  const classArray = () => {
    const classArray = []
    if (isActive) {
      classArray.push('slide--active')
    }
    if (image_position === 'right') {
      classArray.push('slide--invert')
    }
    if (image_position === 'top') {
      classArray.push('slide--column')
    }
    return classArray.join(' ')
  }

  return (
    <div ref={slideRef} className={`slide ${classArray()}`}>
      <Untabbable disabled={isActive}>
        <div className="slide__content">
          <div className="slide__image">
            <div className="content imageContent">
              <div className="slide__hotspots">
                <div className="content" ref={hotspotRef}>
                  {hotspotItems
                    ? hotspotItems.map((hotspot, index) => {
                      return <Hotspot {...hotspot} key={index} />
                    })
                    : null}
                </div>
              </div>

              <div className="image" ref={imageRef}>
                {isMobile() ? (
                  <img className="mobileImage" alt={image_alt_text || 'Slide Data'} src={hotspotImage} />
                ) : (
                  <img className="desktopImage" alt={image_alt_text || 'Slide Data'} src={hotspotImage} />
                )}
              </div>
            </div>
          </div>

          <div className="slide__text" ref={textRef}>
            <TextModule text={body} />

            {audio && (
              <div className="slide__audio slide__audio-desktop">
                <AudioPlayer audioFile={audio} />
              </div>
            )}

            {show_continue_cta && (
              <div className="continue-cta-container">
                <button className='button continue-cta' onClick={() => nextSlide()}>
                  <span>Continue</span>
                  <NextIcon />
                </button>
              </div>
            )}

            {hotspots.items.length > 0 && (
              <div>
                <LearnMoreHrStyled />
                <LearnMoreHeadingStyled>
                  {appMeta?.learn_more_text || 'Learn More'}
                </LearnMoreHeadingStyled>

                {hotspots.items.map((hotspot, index) => {
                  return (
                    <LearnMoreHotspotContainerStyled key={index}>
                      <InfoPopup {...hotspot} />
                    </LearnMoreHotspotContainerStyled>
                  )
                })}
              </div>
            )}
          </div>

          {audio && (
            <div className="slide__audio slide__audio-mobile">
              <AudioPlayer audioFile={audio} />
            </div>
          )}
        </div>
      </Untabbable>
    </div>
  )
}

export default InfoSlide
