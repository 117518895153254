import React, { useState, useContext, Fragment } from 'react'
import TimelinePage from './../pages/TimelinePage'
import ModalContext from './../Context/modal'
import { useHistory } from 'react-router-dom'
import { ReactComponent as MenuIcon } from './../Icons/icon-menu.svg'
import { ReactComponent as CloseIcon } from './../Icons/icon-close.svg'
import { ReactComponent as PrevIcon } from './../Icons/icon-prev-arrow.svg'
import { ReactComponent as NextIcon } from './../Icons/icon-next-arrow.svg'
import useInteractiveSlug from '../hooks/useInteractiveSlug'
import useSelectTimeline from '../hooks/useSelectTimeline'

const Header = (props) => {
  const {
    selectedIndex,
    nextSlide,
    previousSlide,
    slideCount,
    slideIsAnimating,
    personData,
    goToStep,
    prevActive,
    nextActive,
    timelineData,
  } = props

  const { url } = useInteractiveSlug()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [showTimelineModal, setShowTimelineModal] = useState(false)
  const { setModalData, setShowMediaModal } = useContext(ModalContext)

  const selectedTimeline = useSelectTimeline(timelineData, personData)

  const isPrevDisabled = () => {
    if (slideIsAnimating || !prevActive) {
      return true
    } else {
      return false
    }
  }

  const isNextDisabled = () => {
    if (slideIsAnimating || !nextActive) {
      return true
    } else {
      return false
    }
  }

  return (
    <header className="sliderHeader">
      <div className="panel panel--first">
        <div className="sliderHeader__main">
          <button
            className="backLink"
            onClick={() => {
              history.push(url)
              goToStep('personSelect')
            }}
          >
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5193 6.16241L1.74934 1.04507C1.24502 0.7508 0.731366 1.4121 1.14126 1.82793L4.88109 5.62192C5.64879 6.40073 5.64861 7.65175 4.88069 8.43035L1.14116 12.2219C0.731134 12.6376 1.24466 13.299 1.74905 13.0049L10.5191 7.88994C11.1806 7.50413 11.1807 6.54836 10.5193 6.16241Z"
                fill="currentColor"
              />
            </svg>
            <span className="backLink__extra">All </span> Stories
          </button>

          <div className="title">
            <div className="title__header">
              <img
                alt={`${personData.character_name}'s Avatar`}
                className="title__avatar"
                src={personData.navigation_image}
              />
            </div>
            <h1>{personData.character_name_title}</h1>
          </div>

          <button className="mobileMenu" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <Fragment>
                <span className="sr-only">Close Menu</span>
                <CloseIcon className="mobileMenu__close" />
              </Fragment>
            ) : (
              <Fragment>
                <span className="sr-only">Open Menu</span>
                <MenuIcon />
              </Fragment>
            )}
          </button>

          <div className={`sliderHeader__links ${isOpen ? 'is-open' : ''}`}>
            {selectedTimeline ? (
              <button onClick={() => setShowTimelineModal(true)}>
                {personData.map_timeline_link_title
                  ? personData.map_timeline_link_title
                  : selectedTimeline.title}
              </button>
            ) : null}

            <button
              onClick={() => {
                setModalData(personData.navigation_link_page_id)
                setShowMediaModal(true)
              }}
            >
              {personData.navigation_link_title}
            </button>
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="sliderHeader__controls">
          <div className="sliderHeader__count">
            {selectedIndex + 1} / {slideCount}
          </div>
          <div className="slideHeader__arrows">
            <button
              className={`slideArrow slideArrow--prev ${
                isPrevDisabled() ? 'slideArrow--disabled' : ''
              }`}
              onClick={() => previousSlide()}
            >
              <span className="sr-only">Go to previous slide</span>
              <PrevIcon />
            </button>
            <button
              onClick={() => nextSlide()}
              className={`slideArrow slideArrow--next ${
                isNextDisabled() ? 'slideArrow--disabled' : ''
              }`}
            >
              <span className="sr-only">Go to next slide</span>
              <NextIcon />
            </button>
          </div>
        </div>
      </div>
      <TimelinePage
        personData={personData}
        showTimelineModal={showTimelineModal}
        setShowTimelineModal={setShowTimelineModal}
        selectedTimeline={selectedTimeline}
      />
    </header>
  )
}

export default Header
