import anime from 'animejs/lib/anime.es.js'

const fadeIn = (modal, onComplete) => {
  anime({
    easing: 'linear',
    targets: modal.current,
    translateY: [-10, 0],
    opacity: [0, 1],
    duration: 300,
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
}

const fadeOut = (modal, onComplete) => {
  anime({
    easing: 'linear',
    targets: modal.current,
    translateY: [0, 10],
    opacity: [1, 0],
    duration: 300,
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
}

const slideOut = (modal, onComplete) => {
  anime({
    easing: 'linear',
    targets: modal.current,
    translateX: [0, '-100%'],
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
    duration: 550,
  })
}

export { fadeIn, fadeOut, slideOut }
