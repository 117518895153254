import React, { useState } from 'react'
import DateSection from './DateSection'
import { ReactComponent as ChevronIcon } from './../../Icons/icon-chevron-down.svg'

const YearSection = (props) => {
  const { title, items, description, subtitle, number } = props
  const [showText, setShowText] = useState(false)

  const canExpand = () => {
    if (description) {
      return true
    } else {
      return false
    }
  }

  const generateButton = (text) => {
    if (canExpand()) {
      return (
        <button
          tabIndex="0"
          onClick={() => {
            setShowText(!showText)
          }}
        >
          {text} <ChevronIcon />
        </button>
      )
    } else {
      return text
    }
  }

  return (
    <div
      className={`yearSection ${canExpand() ? 'yearSection--expander' : ''} ${
        showText ? 'yearSection--expanded' : ''
      }`}
    >
      {number ? (
        <div className="dateSection__pointer">
          <span>{number}</span>
        </div>
      ) : (
        <div className="pointer pointer--basic"></div>
      )}

      <h2 className="yearSection__year">{generateButton(title)}</h2>
      <div className="yearSection__excerpt">
        <p>{subtitle}</p>
      </div>

      {showText ? (
        <div className="yearSection__text">
          <p>{description}</p>
        </div>
      ) : null}

      <div className="yearSection__content">
        {items.map((dateData, index) => {
          return <DateSection {...dateData} key={index} />
        })}
      </div>
    </div>
  )
}

export default YearSection
