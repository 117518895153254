import Panzoom from '@panzoom/panzoom' //docs: https://github.com/timmywil/panzoom
import { getOffset } from '@area17/a17-helpers'

const panZoomConfig = {
  //   minScale: 1,
  startScale: 1,
  step: 0.3,
  //   contain: 'outside',
}

class PanZoomController {
  constructor(panZoomElem) {
    this.control = {}
    this.panZoomNode = panZoomElem
    this.largeZoomMode = false
    this.parent = this.panZoomNode.parentNode
    this.load()
  }

  // used to keep hotspots the same size when zooming in or out
  _transformHotspots(scale) {
    // collect all info bubbles/hotspots within parent and scale them accordingly
    const hotspots = [...this.parent.querySelectorAll('.hotspot')]
    hotspots.forEach((hotspot) => {
      hotspot.style.transform = `scale(${1 / scale})`
    })
  }

  zoomIn() {
    this.control.zoomIn()
  }
  zoomOut() {
    this.control.zoomOut()
  }

  // center on a node in the panArea
  centerOn(node) {
    const elem = node
    const elemOffset = getOffset(elem)
    const parentOffset = getOffset(this.parent)
    let relativePos = {}
    relativePos.top = elemOffset.top - parentOffset.top
    relativePos.right = elemOffset.right - parentOffset.right
    relativePos.bottom = elemOffset.bottom - parentOffset.bottom
    relativePos.left = elemOffset.left - parentOffset.left
    let resetX = -1 * relativePos.left
    let resetY = -1 * relativePos.top
    this.control.pan(resetX, resetY, {
      relative: true,
    })
    this.control.pan(
      this.parent.offsetWidth / 2,
      this.parent.offsetHeight / 2,
      {
        relative: true,
      },
    )
  }

  setFreeZoom() {
    this.control.setOptions({ contain: '' })
    this.largeZoomMode = true
  }

  initZoomChange() {
    this.panZoomNode.addEventListener('panzoomchange', (event) => {
      const currentScale = event.detail.scale
      this._transformHotspots(currentScale)
      const changeEvent = new Event('panZoomUpdate')
      document.dispatchEvent(changeEvent)

      // const hotspots = [...this.parent.querySelectorAll('.hotspot')]

      // hotspots.forEach((elem) => {
      //   const offset = getOffset(elem)
      // })
    })
  }

  initZoomStart() {
    this.panZoomNode.addEventListener('panzoomstart', (event) => {
      // hideAll({ exclude: this.excludeRef })
      // if (this.largeZoomMode === false) {
      // the node you want to pan
      // const elem = this.panZoomNode
      // the zoomable area (the parent of the zoomnode)
      // const panArea = this.parent
      // this is not one size fits all and might not be your expected outcome
      // for this project if the image is ever too large for both its offset,
      // set to freezoom
      // if (elem.offsetHeight < panArea.offsetHeight) {
      //   this.setFreeZoom()
      // }
      // if (elem.offsetWidth < panArea.offsetWidth) {
      //   this.setFreeZoom()
      // }
      // }
    })
  }

  nodeSetUp() {
    const height = this.panZoomNode.offsetHeight
    const width = this.panZoomNode.offsetWidth

    if (width > height) {
      this.type = 'landscape'
    }
    if (height > width) {
      this.type = 'protrait'
    }
    if (height === width) {
      this.type = 'square'
    }

    this.parent.classList.add(`type--${this.type}`)
  }

  centerPan(animate = false) {
    const height = document.body.offsetHeight
    const nodeHeight = this.panZoomNode.offsetHeight
    const nodeWidth = this.panZoomNode.offsetWidth
    const width = document.body.offsetWidth

    this.control.pan((width - nodeWidth) / 2, (height - nodeHeight) / 2)
  }

  setImageSize() {
    const windowHeight = document.body.offsetHeight
    const windowWidth = document.body.offsetWidth
    const thing = this.panZoomNode.querySelector('.mapComponent__map')

    if (windowWidth >= windowHeight) {
      thing.style.height = `auto`
      thing.style.width = `${windowWidth}px`
    } else {
      thing.style.height = `${windowHeight}px`
      thing.style.width = `auto`
    }

    this.centerPan()
  }

  load() {
    // the node you want to pan
    this.control = Panzoom(this.panZoomNode, panZoomConfig)

    this.initZoomChange()
    this.initZoomStart()
    this.nodeSetUp()

    // for debug purposes
    window.panZoom = this.control
    window.panZoomClass = this
    setTimeout(() => {
      this.centerPan()
    })
  }
}

export default PanZoomController
