import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import App from './App'
import ModalContext from './Context/modal'

const AppRouter = (props) => {
  const [modalData, setModalData] = useState({})
  const [showMediaModal, setShowMediaModal] = useState(false)
  const slug = document.querySelector('#root').getAttribute('data-slug')

  return (
    <ModalContext.Provider
      value={{
        modalData,
        setModalData,
        showMediaModal,
        setShowMediaModal,
      }}
    >
      <Router>
        <Switch>
          <Route path={`/interactives/${slug}/app/glossary`}>
            <App route="glossary" />
          </Route>

          <Route path={`/interactives/${slug}/app/:person/:slide`}>
            <App />
          </Route>

          <Route path={`/interactives/${slug}/app/:person`}>
            <App />
          </Route>

          <Route path={`/interactives/${slug}/app`}>
            <App />
          </Route>
        </Switch>
      </Router>
    </ModalContext.Provider>
  )
}

export default AppRouter
