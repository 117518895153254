import React, { useState } from 'react'
import ExplorerControls from './ExplorerControls'
import { GenericModal } from 'moar-interactive-shared'
import panZoomController from './../../utils/panZoom'

const PanZoom = ({ closeExplorer, explorerOpen, image, imageAltText, zoomText }) => {
  const [panZoom, setPanZoom] = useState();
  const hasText = () => zoomText;

  return (
    <GenericModal showModal={explorerOpen} overlayName={'zoomModal'}>
      <div class={`panZoomModule ${hasText() ? 'panZoomModule--info' : ''}`}>
        <div class="zoomArea__outer">
          {panZoom ? (
            <ExplorerControls
              panZoom={panZoom}
              closeExplorer={closeExplorer}
            />
          ) : null}

          <div className="zoomArea">
            <img
              className="zoomNode"
              alt={imageAltText || zoomText || 'Zoomer'}
              src={image}
              onLoad={() => {
                const panzoomElem = document.querySelector('.zoomNode')
                const panState = new panZoomController(panzoomElem)
                setPanZoom(panState)
              }}
            />
          </div>
        </div>
        {zoomText ? (
          <div className="panZoomModule__info">
            <div className="panZoomModule__attr">
              <p>{zoomText}</p>
            </div>
          </div>
        ) : null}
      </div>
    </GenericModal>
  )
}

export default PanZoom;
