import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ShareIcon from '../../components/icons/ShareIcon';
import useClickOutside from '../../hooks/useClickOutside';

const ButtonStyled = styled.button`
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    width: 36px;

    &:hover {
        background-color: #e5e0d7;
    }
`;

const MenuContainerStyled = styled.div`
    background-color: white;
    border: 1px solid #23262c;
    box-shadow:
        rgb(255, 255, 255) 0px 0px 0px 0px,
        rgba(26, 26, 26, 0.05) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
    margin-top: 0.5rem;
    position: absolute;
    right: 0;
    transform-origin: top right;
    width: 10rem;
    z-index: 40;
`;

const MenuListStyled = styled.ul`
    /*  */
`;

const MenuItemStyled = styled.li`
    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }
`;

const MenuLinkStyled = styled.a`
    color: black;
    display: block;
    font-family: avenir, sans-serif;
    font-size: 1.063rem;
    line-height: 1.5rem;
    padding: 0.75rem 1rem 0.75rem 1rem;
    text-decoration: none;

    &:hover {
        background-color: #ecf0f5;
    }
`;

const GlossaryShareMenu = ({ title }) => {
    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [facebookUrl, setFacebookUrl] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [emailUrl, setEmailUrl] = useState('');
    
    useClickOutside(containerRef, () => setIsOpen(false));

    useEffect(() => {
        const title = encodeURIComponent(document.title);
        const url = encodeURIComponent(window.location.href);

        setFacebookUrl(`https://facebook.com/sharer/sharer.php?u=${url}`);
        setTwitterUrl(`https://twitter.com/intent/tweet/?text=${title}%20via%20@amrevmuseum&url=${url}`);
        setEmailUrl('mailto:?subject='.concat(title) + '&body='.concat(url));
    }, [title]);

    return (
        <div ref={containerRef} style={{ position: 'relative' }}>
            <ButtonStyled aria-label="Share" onClick={() => setIsOpen(!isOpen)}>
                <ShareIcon />
            </ButtonStyled>

            {isOpen && (
                <MenuContainerStyled>
                    <MenuListStyled>
                        <MenuItemStyled>
                            <MenuLinkStyled
                                aria-label="Share via Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={twitterUrl}
                                onClick={() => setIsOpen(false)}
                            >
                                Twitter
                            </MenuLinkStyled>
                        </MenuItemStyled>
                    
                        <MenuItemStyled>
                            <MenuLinkStyled
                                aria-label="Share via Facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={facebookUrl}
                                onClick={() => setIsOpen(false)}
                            >
                                Facebook
                            </MenuLinkStyled>
                        </MenuItemStyled>

                        <MenuItemStyled>
                            <MenuLinkStyled
                                aria-label="Share via Email"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={emailUrl}
                                onClick={() => setIsOpen(false)}
                            >
                                Email
                            </MenuLinkStyled>
                        </MenuItemStyled>
                    </MenuListStyled>
                </MenuContainerStyled>
            )}
        </div>
    );
}

export default GlossaryShareMenu;
