import React from 'react'

const InfoBubble = React.forwardRef((props, ref) => {
  const { active, cta_text, handleClick } = props;

  return (
    <button
      aria-label={`Hotspot for ${cta_text}`}
      ref={ref}
      className={`infoBubble ${active ? 'infoBubble--active' : ''}`}
      onClick={() => {
        if (handleClick) {
          handleClick()
        }
      }}
    >
      <span>i</span>
    </button>
  )
})

export default InfoBubble
