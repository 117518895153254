import React from 'react'
import YearSection from './YearSection'

const Timeline = ({ timelineData }) => {
  return (
    <div className="timeline">
      <div className="timeline__content">
        {timelineData.groups.map((data, index) => {
          return <YearSection {...data} key={index} />
        })}
      </div>
    </div>
  )
}

export default Timeline
