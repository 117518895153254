import React, { useEffect, useState } from 'react';
import Timeline from './../components/Timeline'
import { GenericModal, Button } from 'moar-interactive-shared'

const TimelinePage = ({
  showTimelineModal,
  setShowTimelineModal,
  selectedTimeline,
  personData,
}) => {
  const [timelineDataToShow, setTimelineDataToShow] = useState(selectedTimeline);
  const [showOnlyKeyEvents, setShowOnlyKeyEvents] = useState(false);

  useEffect(() => {
    if (showOnlyKeyEvents) {
      setTimelineDataToShow({
        ...selectedTimeline,
        groups: selectedTimeline.groups.map(group => ({
          ...group,
          items: group.items.filter(item => item.number !== null)
        })),
      });
    } else {
      setTimelineDataToShow(selectedTimeline);
    }
  }, [selectedTimeline, showOnlyKeyEvents]);

  if (!selectedTimeline) {
    return null
  }

  return (
    <GenericModal
      contentLabel={selectedTimeline.title}
      showModal={showTimelineModal}
      overlayName="timelineModal"
      onClose={() => {
        setShowTimelineModal(false)
      }}
    >
      <div className="timelineSection" tabIndex="0">
        <div className="timelineSection__intro timelineSection__intro--mobile">
          <div className="timelineSection__header">
            <div className="timelineSection__avatar">
              <img alt="" src={personData.navigation_image} />
            </div>
            <h1 className="timelineSection__title">{selectedTimeline.title}</h1>
          </div>

          <div className="timelineSection__info">
            <p>{selectedTimeline.description}</p>
            <p style={{ marginTop: '2rem' }}>
              The numbered points along the timeline call out events specific to {personData.character_name}'s life.
            </p>

            <div className="timelineSection__filter-container" style={{ marginTop: '2rem' }}>
              <label>
                <input
                  className="timelineSection__filter-checkbox"
                  type="checkbox"
                  onChange={e => setShowOnlyKeyEvents(e.target.checked)}
                />

                <span className="timelineSection__filter-text">
                  {timelineDataToShow && (
                    <>
                      {timelineDataToShow.key_event_filter_label || `Show only events that are specific to ${personData.character_name}'s life.`}
                    </>
                  )}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="timelineSection__image">
          {selectedTimeline.image ? (
            <div className="imageWrap">
              <Button
                label={selectedTimeline.external_map_cta_text}
                url={selectedTimeline.external_map_url}
                external={true}
                lightBg={true}
              />
              <img alt={`${personData.character_name}'s Map`} src={selectedTimeline.image} />
            </div>
          ) : null}
        </div>

        <div className="timelineSection__timeline">
          <div className="timelineSection__intro timelineSection__intro--desktop">
            <div className="timelineSection__header">
              <div className="timelineSection__avatar">
                <img alt={`${personData.character_name}'s Avatar`} src={personData.navigation_image} />
              </div>
              <h1 className="timelineSection__title">{selectedTimeline.title}</h1>
            </div>

            <div className="timelineSection__info">
              <p>{selectedTimeline.description}</p>
              <p style={{ marginTop: '2rem' }}>
                The numbered points along the timeline call out events specific to {personData.character_name}'s life.
              </p>

              <div className="timelineSection__filter-container" style={{ marginTop: '2rem' }}>
                <label>
                  <input
                    className="timelineSection__filter-checkbox"
                    type="checkbox"
                    onChange={e => setShowOnlyKeyEvents(e.target.checked)}
                  />

                  <span className="timelineSection__filter-text">
                    {timelineDataToShow && (
                      <>
                        {timelineDataToShow.key_event_filter_label || `Show only events that are specific to ${personData.character_name}'s life.`}
                      </>
                    )}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <Timeline timelineData={timelineDataToShow} />
        </div>
      </div>
    </GenericModal>
  )
}

export default TimelinePage
