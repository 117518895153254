import { useState } from 'react'

const useInteractiveSlug = () => {
  const [url] = useState(
    `/interactives/${document
      .querySelector('#root')
      .getAttribute('data-slug')}/app`,
  )

  return { url }
}

export default useInteractiveSlug
