import { useEffect, useState } from 'react'

const useSelectTimeline = (timelineData, personData) => {
  const [selectedTimeline, setSelectedTimeline] = useState(null)
  useEffect(() => {
    if (!timelineData) return
    const selectedData = timelineData.find(
      (timeline) => timeline.id === personData.map_timeline_id,
    )
    setSelectedTimeline(selectedData)
  }, [personData, timelineData])

  return selectedTimeline
}

export default useSelectTimeline
