import anime from 'animejs/lib/anime.es.js'

const fadeIn = (text, button, onComplete) => {
  anime
    .timeline({
      easing: 'easeOutCubic',
      complete: () => {
        if (onComplete) {
          onComplete()
        }
      },
    })
    .add(
      {
        targets: button.current,
        opacity: [0, 1],
        duration: 2800,
        translateY: [-30, 0],
      },
      1800,
    )
    .add(
      {
        targets: text.current,
        opacity: [0, 1],
        duration: 2800,
        translateY: [-30, 0],
      },
      500,
    )
}

const fadeOut = (text, button, onComplete) => {
  anime({
    easing: 'linear',
    targets: text.current,
    translateY: [0, -10],
    opacity: [1, 0],
    duration: 500,
  })
  anime({
    easing: 'linear',
    targets: button.current,
    translateY: [0, -10],
    opacity: [1, 0],
    duration: 500,
    delay: 300,
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
}

export { fadeIn, fadeOut }
