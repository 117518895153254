import React, { useRef, useEffect, useState } from 'react'
import usePrevious from './../hooks/usePrevious'
import { slide } from './../animations/peopleSliderAnimation'
import { ReactComponent as PrevIcon } from './../Icons/icon-prev-arrow.svg'
import { ReactComponent as NextIcon } from './../Icons/icon-next-arrow.svg'

const PeopleSlider = (props) => {
  const { people } = props
  const [prevDisabled, setPrevDisabled] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)
  const personSelect = useRef()
  const [revealedIndex, setRevealedIndex] = useState(0)
  const prevIndex = usePrevious(revealedIndex)
  const revealCount = props.people.length - 4

  const next = () => {
    let newIndex = revealedIndex + 1
    setRevealedIndex(newIndex)
  }

  const prev = () => {
    let newIndex = revealedIndex - 1
    setRevealedIndex(newIndex)
  }

  useEffect(() => {
    if (personSelect.current) {
      const slider = personSelect.current
      slider.style.height = `${120 * 4 + 8}px`
    }
  }, [personSelect])

  useEffect(() => {
    slide(personSelect, prevIndex, revealedIndex)
  }, [revealedIndex]) /* eslint-disable-line */

  useEffect(() => {
    if (revealedIndex === 0) {
      setPrevDisabled(true)
    } else {
      setPrevDisabled(false)
    }
    if (revealedIndex === revealCount) {
      setNextDisabled(true)
    } else {
      setNextDisabled(false)
    }
  }, [revealedIndex]) /* eslint-disable-line */

  const isSelected = (personID) => {
    if (props.selected.id === personID) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="personSelector">
      {people.length >= 5 ? (
        <button
          aria-label="Previous"
          disabled={prevDisabled}
          className="arrow--prev"
          onClick={() => prev()}
        >
          <PrevIcon />
        </button>
      ) : null}

      <div ref={personSelect} className="slider">
        {people.length > 1 ? (
          <ul className="inner">
            {people.map((person, personIndex) =>(
              <li aria-label={`${personIndex + 1} of ${people.length}, ${person.character_name}`}>
                <button
                  tabIndex="0"
                  className={`carousel-cell ${
                    isSelected(person.id) ? 'carousel-cell--selected' : ''
                  }`}
                  key={person.id}
                  onClick={() => {
                    if (isSelected(person.id)) {
                      return
                    } else {
                      props.selectNewPerson(person)
                    }
                  }}
                >
                  <img alt={`${personIndex + 1} of ${people.length}, ${person.character_name}`} src={person.navigation_image} />
                </button>
              </li>
            ))}
          </ul>
        ) : null}
      </div>

      {people.length >= 5 ? (
        <button
          aria-label="Next"
          className="arrow--next"
          disabled={nextDisabled}
          onClick={() => next()}
        >
          <NextIcon />
        </button>
      ) : null}
    </div>
  )
}

export default PeopleSlider
