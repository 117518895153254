import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import InfoBubble from './InfoBubble'
import InfoPopup from './InfoPopup'
import { hideAll } from 'tippy.js'
import 'tippy.js/animations/scale-subtle.css'

const Hotspot = (props) => {
  const [active, setActive] = useState(false)

  const setIndex = () => {
    if (active) {
      return 30
    } else {
      return 1
    }
  }

  const styles = () => {
    return {
      top: `calc(${props.y}% - 25px)`,
      left: `calc(${props.x}% - 25px)`,
      zIndex: setIndex(),
    }
  }

  return (
    <div className="hotspot" style={styles()}>
      <Tippy
        content={<InfoPopup {...props} />}
        animation={'scale-subtle'}
        interactive={true}
        placement={'bottom'}
        trigger="mouseenter focus click"
        onShow={(instance) => {
          hideAll({ exclude: instance })
          setActive(true)
        }}
        onHide={() => setActive(false)}
      >
        <InfoBubble active={active} {...props} />
      </Tippy>
    </div>
  )
}

export default Hotspot
