import anime from 'animejs/lib/anime.es.js'

const slide = (slider, prevIndex, revealedIndex) => {
  anime({
    easing: 'easeOutQuad',
    duration: 200,
    targets: slider.current.querySelector('.inner'),
    translateY: [
      prevIndex * (-1 * 120),
      -1 * 120 * revealedIndex - revealedIndex * 2,
    ],
  })
}

export { slide }
