import anime from 'animejs/lib/anime.es.js'

const fadeIn = (onBegin, onComplete) => {
  anime({
    easing: 'linear',
    targets: document.querySelector('.loadScreen'),
    opacity: [1, 0],
    begin: () => {
      if (onBegin) {
        onBegin()
      }
    },
    complete: () => {
      document.querySelector('.loadScreen').style.display = 'none'
      if (onComplete) {
        onComplete()
      }
    },
  })
}

const fadeOut = (onComplete) => {
  anime({
    easing: 'linear',
    targets: document.querySelector('.loadScreen'),
    opacity: [0, 1],
    begin: () => {
      document.querySelector('.loadScreen').style.display = 'block'
    },
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
}

export { fadeIn, fadeOut }
