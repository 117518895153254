import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const ListStyled = styled.ul`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    overflow-x: scroll;
    padding-bottom: 20px;

    @media (min-width: 768px) {
        overflow-x: auto;
        padding-bottom: 0;
    }

    @media print {
        display: none;
    }
`;

const LetterActiveStyled = styled.button`
    align-items: center;
    background: transparent;
    border: none;
    color: #1a2e44;
    display: flex;
    font-family: avenir;
    font-size: 16px;
    font-weight: bold;
    height: 28px;
    justify-content: center;
    width: 28px;

    &:hover {
        background: #1a2e44;
        color: white;
    }
`;

const LetterInactiveStyled = styled.div`
    align-items: center;
    color: #1a2e44;
    display: flex;
    font-family: avenir;
    font-size: 16px;
    font-weight: bold;
    height: 28px;
    justify-content: center;
    opacity: 0.2;
    width: 28px;
`;

function GlossaryNav({ glossaryData }) {
    const [presentLetters, setPresentLetters] = useState([]);

    useEffect(() => {
        const terms = glossaryData.map((item) => item.title);
        const firstLetters = terms.map((term) => term.slice(0, 1).toUpperCase());
        setPresentLetters([...new Set(firstLetters)])
    }, [glossaryData])

    const allLetters = useMemo(() => {
        const alpha = Array.from(Array(26)).map((e, i) => i + 65);
        return alpha.map((x) => String.fromCharCode(x));
    }, []);

    // enables smooth scrolling to letter anchor and updates hash for back button
    const handleClick = (letter) => {
        const sectionToScrollTo = document.querySelector(`#${letter.toLowerCase()}`);
        if (sectionToScrollTo) {
            sectionToScrollTo.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        window.location.hash = letter.toLowerCase();
    };

    return (
        <ListStyled>
            {allLetters.map((letter) => {
                const isLetterPresent = presentLetters.includes(letter);

                return (
                    <li key={letter}>
                        {isLetterPresent && (
                            <LetterActiveStyled onClick={() => handleClick(letter)}>
                                {letter}
                            </LetterActiveStyled>
                        )}

                        {!isLetterPresent && (
                            <LetterInactiveStyled>
                                {letter}
                            </LetterInactiveStyled>
                        )}
                    </li>
                );
            })}
        </ListStyled>
    );
}

export default GlossaryNav;
