import React from 'react'
import { ReactComponent as ExternalIcon } from './../Icons/icon-external.svg'

const Cta = (props) => {
  const { isExternal, href, icon, label } = props

  const generateIcon = () => {
    if (isExternal) {
      return <ExternalIcon />
    } else {
      return (
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5195 6.16253L1.74958 1.04519C1.24527 0.750922 0.73161 1.41222 1.1415 1.82805L4.88133 5.62204C5.64903 6.40086 5.64885 7.65188 4.88093 8.43047L1.14141 12.222C0.731378 12.6377 1.2449 13.2992 1.7493 13.005L10.5193 7.89007C11.1808 7.50425 11.1809 6.54849 10.5195 6.16253Z"
            fill="#004899"
          />
        </svg>
      )
    }
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={`cta cta--primary ${isExternal ? 'cta--external' : ''}`}
      href={href || '#'}
    >
      {label}
      {icon ? generateIcon() : null}
    </a>
  )
}

export default Cta
