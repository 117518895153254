import React, { useState } from 'react'
import { ReactComponent as ChevronIcon } from './../../Icons/icon-chevron-down.svg'

const DateSection = (props) => {
  const { title, subtitle, description, number } = props

  const [showText, setShowText] = useState(false)

  const canExpand = () => {
    if (description) {
      return true
    } else {
      return false
    }
  }

  const generateButton = (text) => {
    if (canExpand()) {
      return (
        <button
          tabIndex="0"
          onClick={() => {
            setShowText(!showText)
          }}
        >
          {text} <ChevronIcon />
        </button>
      )
    } else {
      return text
    }
  }

  return (
    <div
      className={`dateSection  ${canExpand() ? 'dateSection--expander' : ''} ${
        showText ? 'dateSection--expanded' : ''
      }`}
    >
      {number ? (
        <div className="dateSection__pointer">
          <span>{number}</span>
        </div>
      ) : (
        <div className="pointer pointer--basic"></div>
      )}

      <div className="dateSection__content">
        <h2 className="dateSection__heading">{generateButton(title)}</h2>
        <div className="dateSection__excerpt">
          <p>{subtitle}</p>
        </div>

        {showText ? (
          <div className="dateSection__text">
            <p>{description}</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default DateSection
