import React, { useEffect, useContext, useState } from 'react'
import { GenericModal } from 'moar-interactive-shared'
import MediaModule from './../components/Media/MediaModule'
import isMobile from './../utils/isMobile'
import useWindowSize from '../hooks/useWindowSize'
import ModalContext from './../Context/modal'
import AudioPlayer from './AudioPlayer'
import TextModule from './TextModule'

const MediaModal = ({ detailPages, backgroundColor, darkText }) => {
  const [selectedModal, setSelectedModal] = useState(null)
  const [css, setCss] = useState('')

  const windowSize = useWindowSize()
  const { modalData, showMediaModal, setShowMediaModal } = useContext(
    ModalContext,
  )

  useEffect(() => {
    if (!modalData) return
    const selectedDetailPage = detailPages.find((data) => data.id === modalData)
    setSelectedModal(selectedDetailPage)
    setCss(backgroundColor ? `.modal{background-color: ${backgroundColor} !important;}` : '');
  }, [backgroundColor, detailPages, modalData])

  useEffect(() => {
    // rerender on each width change
  }, [windowSize.width])

  if (!selectedModal) return null

  return (
    <GenericModal
      contentLabel={selectedModal.title}
      overlayName={'mediaModal'}
      showModal={showMediaModal}
      onClose={() => setShowMediaModal(false)}
      modifier={darkText ? 'dark-text' : ''}
    >
      <style>{ css }</style>
      <div className="detailPage" tabIndex="0">
        <div className="detailPage__col detailPage__col--main" tabIndex="1">
          <div className="detailPage__header">
            <h2>{selectedModal.title}</h2>
          </div>

          {isMobile() ? (
            <div className="detail__media">
              <MediaModule
                canExpand={selectedModal.image_zoomable}
                image={selectedModal.image}
                imageAltText={selectedModal.image_alt_text}
                attr={selectedModal.image_caption || selectedModal.image_credit}
                zoomText={selectedModal.image_zoomed_caption}
              />
            </div>
          ) : null}

          <TextModule
            text={selectedModal.body}
            isLight={true}
          />

          {selectedModal.audio && (
            <div className="detailPage__audio">
              <AudioPlayer audioFile={selectedModal.audio} />
            </div>
          )}
        </div>

        <div className="detailPage__col" tabIndex="2">
          {!isMobile() ? (
            <div className="detailPage__media">
              <MediaModule
                canExpand={selectedModal.image_zoomable}
                image={selectedModal.image}
                imageAltText={selectedModal.image_alt_text}
                attr={selectedModal.image_caption || selectedModal.image_credit}
                zoomText={selectedModal.image_zoomed_caption}
              />
            </div>
          ) : null}
        </div>
      </div>
    </GenericModal>
  )
}

export default MediaModal
