import React, { useRef } from 'react';
import slugify from 'slugify';
import styled from 'styled-components';
import AudioIcon from '../../components/icons/AudioIcon';

const ContainerStyled = styled.li`
    font-family: avenir;
    margin-bottom: 1.25rem;
`;

const HeadingStyled = styled.h3`
    font-weight: bold;
`;

const AudioButtonStyled = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 24px;
    padding: 0;
    width: 24px;

    @media print {
        display: none;
    }
`;

const AudioPlaceholderStyled = styled.div`
    height: 24px;
`;

const GlossaryItem = ({ item }) => {
    const audioRef = useRef(null);

    const isAudioMpeg = (url) => {
        return url.includes('.mp3');
    }

    const isAudioOgg = (url) => {
        return url.includes('.ogg');
    }

    const isAudioWav = (url) => {
        return url.includes('.wav');
    }

    return (
        <ContainerStyled id={slugify(item.title.toLowerCase(), '_')} key={slugify(item.title, '_')}>
            <div style={{ alignItems: 'center', display: 'flex', marginBottom: '4px' }}>
                <HeadingStyled>{item.title}</HeadingStyled>

                {item.audio && (
                    <>
                        <AudioButtonStyled style={{ marginLeft: '8px' }} onClick={() => audioRef.current.play()}>
                            <AudioIcon color="black" size={24} />
                        </AudioButtonStyled>

                        <audio ref={audioRef}>
                            {isAudioMpeg(item.audio) && <source src={item.audio} type="audio/mpeg" />}
                            {isAudioOgg(item.audio) && <source src={item.audio} type="audio/ogg" />}
                            {isAudioWav(item.audio) && <source src={item.audio} type="audio/wav" />}
                        </audio>
                    </>
                )}

                {!item.audio && (
                    <AudioPlaceholderStyled />
                )}
            </div>
            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
        </ContainerStyled>
    );
};

export default GlossaryItem;
