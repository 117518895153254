import React from 'react';
import styled from 'styled-components';
import ArrowCircleIcon from "../../components/icons/ArrowCircleIcon";

const ButtonContainerStyled = styled.div`
    animation: fade-in 0.5s linear;
    display: none;
    height: 5rem;
    position: sticky;
    padding-bottom: 10px;
    padding-top: 10px;
    z-index: 10;
    transition: all 0.5s ease-in-out;
    bottom: 5rem;

    @media (min-width: 768px) {
        align-items: center;
        bottom: ${props => props.visible ? '10px' : 'calc(-5rem - 10px)'};
        display: flex;
        justify-content: center;
        padding-bottom: 0;
        padding-top: 0;
    }
`;

const ButtonStyled = styled.button`
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    padding-left: 1.5rem;
`;

const ButtonTextStyled = styled.span`
    font-size: 14px;
    font-weight: bold;
    margin-right: 0.5rem;
`;

const BackToTop = ({ isVisible, scrollRef }) => {
    const handleClick = () => {
        window.location.hash = '';
        scrollRef.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };

    return (
        <ButtonContainerStyled visible={isVisible}>
            <ButtonStyled onClick={() => handleClick()}>
                <ButtonTextStyled>Back to top</ButtonTextStyled>
                <ArrowCircleIcon />
            </ButtonStyled>
        </ButtonContainerStyled>
    );
}

export default BackToTop;
