import { useEffect, useState } from 'react'
import Flickity from 'flickity'

const useHorizontalSlides = (slides, setShowEnd, params) => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [slideController, setSlideController] = useState()
  const [sliderReady, setSliderReady] = useState(false)
  const [nextActive, setNextActive] = useState(false)
  const [prevActive, setPrevActive] = useState(false)

  const next = () => {
    if (selectedIndex + 2 > slides.length) {
      setShowEnd(true)
    } else {
      slideController.next()
    }
  }

  const prev = () => {
    slideController.previous()
  }

  const updateData = (slider) => {
    setSelectedIndex(slider.selectedIndex)

    const currentSlideData = slides[slider.selectedIndex]

    // on an option page we need to disable next
    if (currentSlideData.type === 'decision') {
      setNextActive(false)
    } else {
      if (slider.selectedIndex + 1 === slides.length) {
        setNextActive(true)
      } else {
        setNextActive(slider.nextButton.isEnabled)
      }
    }
    setPrevActive(slider.prevButton.isEnabled)
  }

  useEffect(() => {
    let startingSlide = 0
    if (params.slide) {
      startingSlide = params.slide - 1
    }
    const main = document.querySelector('.main')
    const slider = new Flickity(main, {
      initialIndex: startingSlide,
      pageDots: false,
      draggable: false,
    })

    setSliderReady(true)
    updateData(slider)
    slider.on('change', () => {
      updateData(slider)
    })

    setSlideController(slider)
  }, [])

  return {
    nextSlide: next,
    previousSlide: prev,
    sliderReady,
    selectedIndex,
    nextActive,
    prevActive,
  }
}
export default useHorizontalSlides
