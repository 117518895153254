import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Header from './../components/Header'
import useHorizontalSlides from './../hooks/useHorizontalSlides'
import SlideControls from './../components/SlideControls'
import Slide from './Slide'
import EndModal from '../components/EndModal'
import hexToRgb from '../utils/hexToRgb'

const SlidesPage = ({
  slides,
  slideIsAnimating,
  setSlideIsAnimating,
  goToStep,
  pageReady,
  personData,
  appMeta,
  timelineData,
}) => {
  const sliderRef = useRef(null);
  const [slideCount, setSlideCount] = useState(0)
  const params = useParams()
  const [showEnd, setShowEnd] = useState(false)

  const {
    nextSlide,
    previousSlide,
    sliderReady,
    selectedIndex,
    nextActive,
    prevActive,
  } = useHorizontalSlides(slides, setShowEnd, params)

  useEffect(() => {
    setSlideCount(slides.length + 1) // all slides + 1 endslide
  }, [slides])

  useEffect(() => {
    sliderRef.current.focus();
  }, [selectedIndex])

  const generateCustomCss = () => {
    let customCss = '';
    if ('story_background_color' in appMeta && appMeta.story_background_color) {
      customCss += '.page.page--slides,.sliderHeader{background: ' + appMeta.story_background_color + ' !important;}';
      const rgb = hexToRgb(appMeta.story_background_color);
      if (rgb) {
        customCss += '.selectionOverlay{background: rgba('+rgb.r+','+rgb.g+','+rgb.b+ ', 0.8) !important;}';
      }
    }
    // It might be cleaner to put this in the OptionSlide component, but it never varies
    // and there is no need to update the DOM with it on every slide render.
    if ('option_background_color' in appMeta && appMeta.option_background_color) {
      customCss += '.optionModal .modal{background: ' + appMeta.option_background_color + ' !important;}';
    }

    return customCss;
  }

  return (
    <div className={`page page--slides ${appMeta.is_story_text_dark ? '' : 'text-light' }`}>
      <style>{generateCustomCss()}</style>
      <EndModal
        goToStep={goToStep}
        personData={personData}
        showEnd={showEnd}
      />

      <Header
        appMeta={appMeta}
        timelineData={timelineData}
        slideCount={slideCount}
        slideIsAnimating={slideIsAnimating}
        goToStep={goToStep}
        personData={personData}
        selectedIndex={selectedIndex}
        nextSlide={nextSlide}
        previousSlide={previousSlide}
        nextActive={nextActive}
        prevActive={prevActive}
      />

      <SlideControls
        nextActive={nextActive}
        prevActive={prevActive}
        slideCount={slideCount}
        slideIsAnimating={slideIsAnimating}
        selectedIndex={selectedIndex}
        nextSlide={nextSlide}
        previousSlide={previousSlide}
      />

      <div className="slideSection">
        <div className={`main ${!sliderReady ? 'is-hidden' : ''}`} ref={sliderRef}>
          {slides.map((slide, index) => (
            <Slide
              key={slide.id}
              {...slide}
              appMeta={appMeta}
              pageReady={pageReady}
              index={index}
              nextSlide={nextSlide}
              selectedIndex={selectedIndex}
              slideIsAnimating={slideIsAnimating}
              setSlideIsAnimating={setSlideIsAnimating}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
export default SlidesPage
