import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import BackToTop from './BackToTop';
import GlossaryContent from './GlossaryContent';
import GlossaryNav from './GlossaryNav';
import GlossaryShareMenu from './GlossaryShareMenu';
import DownloadIcon from '../../components/icons/DownloadIcon';
import PrintIcon from '../../components/icons/PrintIcon';
import { Heading1Styled } from '../../components/Styled';

const ContainerOuterStyled = styled.div`
    height: 100%;
    overflow: auto;
    margin-bottom: 5rem;
    padding-top: 2rem;
    scroll-behavior: smooth;

    @media print {
        overflow: visible;
    }
`;

const ContainerInnerStyled = styled.div`
    height: 100%;
`;

const HeaderContainerStyled = styled.div`
    align-items: flex-end;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    h1 {
        color: #000;
    }
`;

const ActionContainerStyled = styled.div`
    display: flex;
    gap: 18px;

    @media print {
        display: none;
    }
`;

const ButtonStyled = styled.button`
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    width: 36px;

    &:hover {
        background-color: #e5e0d7;
    }
`;

const GlossaryPdfLinkStyled = styled.a`
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    width: 36px;

    &:hover {
        background-color: #e5e0d7;
    }
`;

const GlossaryPage = ({ glossaryData, glossaryPdf }) => {
    const [scrollDirection, setScrollDirection] = useState('up');
    const scrollRef = useRef(null);
    const prevScrollY = useRef(0);

    // Scroll to letter on page load
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            setTimeout(() => {
                const sectionToScrollTo = document.querySelector(hash);
                if (sectionToScrollTo) {
                    sectionToScrollTo.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, []);

    // Show Back to Top button when scrolling down
    useEffect(() => {
        if (!scrollRef.current) {
            return;
        }

        prevScrollY.current = window.pageYOffset;

        const onScroll = () => {
            const scrollY = scrollRef.current.scrollTop;
            if (Math.abs(scrollY - prevScrollY.current) >= 50) {
                const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up';
                setScrollDirection(newScrollDirection);
                prevScrollY.current = scrollY > 0 ? scrollY : 0;
            }
        };

        scrollRef.current.addEventListener('scroll', onScroll);
        return () => scrollRef.current.removeEventListener('scroll', onScroll);
    }, [scrollRef]);

    return (
        <ContainerOuterStyled className="page" ref={scrollRef}>
            <ContainerInnerStyled className="page--glossary">
                <HeaderContainerStyled>
                    <Heading1Styled as="h1">Glossary</Heading1Styled>
                    <ActionContainerStyled>
                        <GlossaryShareMenu />

                        <ButtonStyled onClick={() => window.print()}>
                            <PrintIcon />
                        </ButtonStyled>

                        {glossaryPdf && (
                            <GlossaryPdfLinkStyled
                                target="_blank"
                                href={glossaryPdf}
                            >
                                <DownloadIcon />
                                <span className="sr-only">Download Glossary PDF</span>
                            </GlossaryPdfLinkStyled>
                        )}
                    </ActionContainerStyled>
                </HeaderContainerStyled>

                <GlossaryNav glossaryData={glossaryData} />
                <GlossaryContent glossaryData={glossaryData} />
                <BackToTop isVisible={scrollDirection === 'down'} scrollRef={scrollRef}/>
            </ContainerInnerStyled>
        </ContainerOuterStyled>
    );
}

export default GlossaryPage;
