import React from 'react';

const AudioIcon = ({ color = 'white', size = 30 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.4054 5.39058L7.00796 10.5119V18.6879L14.4054 23.8091C14.5321 23.8969 14.6825 23.9439 14.8366 23.9439C15.6735 23.9439 16.352 23.2654 16.352 22.4285V6.77118C16.352 5.93429 15.6735 5.25586 14.8366 5.25586C14.6825 5.25586 14.5321 5.30286 14.4054 5.39058ZM4.30391 10.5116C3.21703 10.5116 2.33594 11.3927 2.33594 12.4796V16.7197C2.33594 17.8065 3.21703 18.6876 4.30391 18.6876H7.00794V10.5116H4.30391Z"
                fill={color}
            />

            <path
                d="M23.3584 21.8956C25.4937 20.1823 26.8624 17.5498 26.8624 14.6001C26.8624 11.6505 25.4937 9.01791 23.3584 7.30469"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
            />

            <path
                d="M21.0225 18.1037C21.7342 17.2808 22.1905 16.0164 22.1905 14.5997C22.1905 13.183 21.7342 11.9186 21.0225 11.0957"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
            />
        </svg>
    );
};

export default AudioIcon;
