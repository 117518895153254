import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading1Styled } from '../../components/Styled';
import GlossaryItem from './GlossaryItem';

const GroupHeadingStyled = styled(Heading1Styled)`
    margin-bottom: 1.25rem;
`;

const GlossaryContent = ({ glossaryData }) => {
    const [itemsByLetter, setItemsByLetter] = useState([]);

    useEffect(() => {
        const itemsWorking = [];

        for (const [index, item] of glossaryData.entries()) {
            const firstLetter = item.title.slice(0, 1).toUpperCase();
            const group = itemsWorking.find((item) => item.letter === firstLetter);

            if (group) {
                group.items = [...group.items, item];
            } else {
                itemsWorking.push({ letter: firstLetter, items: [item] });
            }
        }

        setItemsByLetter(itemsWorking);
    }, [glossaryData]);

    return (
        <div>
            {itemsByLetter.map((group) => (
                <div id={`${group.letter.toLowerCase()}`} key={`glossary-group-${group.letter}`}>
                    <GroupHeadingStyled as="h2">
                        {group.letter}
                    </GroupHeadingStyled>

                    <ul>
                        {group.items.map((item) => <GlossaryItem item={item} />)}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default GlossaryContent;
