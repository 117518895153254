import React, { useRef } from 'react'
import renderHTML from 'react-render-html'
import useGlossary from './../hooks/useGlossary'

const TextModule = ({ text, isLight }) => {
  const textRef = useRef()
  useGlossary(textRef, isLight)

  if (!text) {
    return null
  }

  return <div ref={textRef}>{renderHTML(text)}</div>
}

export default TextModule
