import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as AudioIcon } from './../Icons/icon-audio.svg'

const AudioPlayer = (props) => {
  const { audioFile } = props
  const [playing, setPlaying] = useState(false)
  const audioRef = useRef()
  useEffect(() => {
    const currentAudio = audioRef.current;
    return () => {
      currentAudio.pause();
    }
  }, [])


  return (
    <div className="audioPlayer">
      <button
        className="audioPlayer__trigger"
        onClick={() => {
          if (playing) {
            audioRef.current.pause()
            audioRef.current.currentTime = 0
            setPlaying(false)
          } else {
            audioRef.current.play()
            setPlaying(true)
          }
        }}
      >
        <AudioIcon />
        {playing ? (
          <span className="sr-only">Pause Audio</span>
        ) : (
          <span className="sr-only">Play Audio</span>
        )}
      </button>

      {playing ? (
        <span className="audioPlayer__loading"> - Playing Audio file</span>
      ) : null}

      <audio className="audioPlayer__file" ref={audioRef}>
        <source src={audioFile} type="audio/mpeg" />
      </audio>
    </div>
  )
}

export default AudioPlayer
