import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { ReactComponent as PrevIcon } from './../Icons/icon-prev-arrow.svg'
import { ReactComponent as NextIcon } from './../Icons/icon-next-arrow.svg'

const SlideControls = ({
  nextActive,
  nextSlide,
  prevActive,
  previousSlide,
  selectedIndex,
  slideIsAnimating,
  slideCount,
}) => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(slideIsAnimating || !prevActive);
  const [isNextDisabled, setIsNextDisabled] = useState(slideIsAnimating || !nextActive);

  useEffect(() => {
    setIsPrevDisabled(slideIsAnimating || !prevActive);
  }, [slideIsAnimating, prevActive]);

  useEffect(() => {
    setIsNextDisabled(slideIsAnimating || !nextActive);
  }, [slideIsAnimating, nextActive]);

  return (
    <div className="slideControls">
      <div className="inner">
        <div className="slideControls__count">
          {selectedIndex + 1} / {slideCount}
        </div>

        <div className="slideHeader__arrows">
          <button
            className={classNames('slideArrow slideArrow--prev', {
              'slideArrow--disabled': isPrevDisabled,
            })}
            disabled={isPrevDisabled}
            onClick={() => previousSlide()}
          >
            <span className="sr-only">Go to previous slide</span>
            <PrevIcon />
          </button>

          <button
            className={classNames('slideArrow slideArrow--next', {
              'slideArrow--disabled': isNextDisabled,
            })}
            disabled={isNextDisabled}
            onClick={() => nextSlide()}
          >
            <span className="sr-only">Go to next slide</span>
            <NextIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default SlideControls
