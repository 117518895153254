import React, { useState, useEffect, Fragment } from 'react'
import InfoSlide from './../components/InfoSlide'
import OptionSlide from './../components/OptionSlide'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import useInteractiveSlug from '../hooks/useInteractiveSlug'

const Slide = (props) => {
  const { index, slideIndex, selectedIndex, type } = props
  const { url } = useInteractiveSlug()

  const params = useParams()
  const history = useHistory()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (index === selectedIndex) {
      setIsActive(true)
      history.push(`${url}/${params.person}/${index + 1}`)
    } else {
      setIsActive(false)
    }
  }, [index, selectedIndex]) /* eslint-disable-line */

  return (
    <Fragment>
      {type === 'decision' ? (
        <OptionSlide {...props} isActive={isActive} activeNumber={slideIndex} />
      ) : (
        <InfoSlide {...props} isActive={isActive} activeNumber={slideIndex} />
      )}
    </Fragment>
  )
}

export default Slide
