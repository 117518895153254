import anime from 'animejs/lib/anime.es.js'

const revealPerson = (text, image, selected, onComplete) => {
  anime({
    easing: 'linear',
    targets: document.querySelector('.largePerson'),
    translateX: [30, 0],
    duration: 1000,
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
  anime({
    easing: 'linear',
    targets: image.current.querySelector(`[data-id*="${selected.id}"]`),
    duration: 1000,
    opacity: [0, 1],
  })
  anime({
    easing: 'linear',
    targets: text.current,
    duration: 1000,
    opacity: [0, 1],
    translateX: [-30, 0],
    delay: 400,
  })
}

const hidePerson = (text, image, selected, onComplete) => {
  anime
    .timeline({
      easing: 'linear',
      duration: 500,
      complete: () => {
        onComplete()
      },
    })
    .add(
      {
        easing: 'linear',
        targets: text.current,
        duration: 500,
        opacity: [1, 0],
      },
      0,
    )
    .add(
      {
        easing: 'linear',
        targets: image.current.querySelector(`[data-id*="${selected.id}"]`),
        duration: 500,
        opacity: [1, 0],
      },
      0,
    )
}

export { revealPerson, hidePerson }
