import { useState, useEffect } from 'react'

const useAppMeta = (data) => {
  const [appMeta, setAppMeta] = useState(null)
  useEffect(() => {
    const newData = { ...data }
    delete newData.stories
    delete newData.detailPages
    delete newData.mapTimelines
    setAppMeta(newData)
  }, [data])

  return { appMeta }
}

export default useAppMeta
