import React from 'react'
import { ReactComponent as ChevronIcon } from './../Icons/icon-chevron.svg'

const MobilePersonSelect = ({ handleClick, person }) => {
  return (
    <div
      className="mobileSelect__person"
      onClick={() => {
        if (handleClick) {
          handleClick()
        }
      }}
    >
      <h3>
        {person.cta}
        <ChevronIcon />
      </h3>

      <img alt={`${person.character_name}, Portrait`} src={person.selection_mobile_image} />
    </div>
  )
}

export default MobilePersonSelect
