import React from 'react'
import { GenericModal, Button } from 'moar-interactive-shared'
import Card from './Card'
import { useHistory, useParams } from 'react-router-dom'
import useInteractiveSlug from '../hooks/useInteractiveSlug'
import AudioPlayer from './AudioPlayer'

const EndModal = ({ goToStep, personData, showEnd }) => {
  const history = useHistory()
  const params = useParams()
  const { url } = useInteractiveSlug()

  return (
    <GenericModal modifier={'outro'} showModal={showEnd} overlayName={'outro'}>
      <section className="section--dark">
        <div className="hero">
          <div className="hero__content">
            <div className="hero__inner">
              <h2>{personData.end_panel.title}</h2>
              <p>{personData.end_panel.text}</p>

              {personData.end_panel.audio && (
                <div className="hero__audio">
                  <AudioPlayer audioFile={personData.end_panel.audio} />
                </div>
              )}

              <div className="hero__controls">
                <Button
                  iconPosition="before"
                  label="Start over"
                  ghost={true}
                  handleClick={() => {
                    history.push(`${url}/${params.person}`)
                    goToStep('slides', true)
                  }}
                />
                <Button
                  label={personData.end_panel.cta_text}
                  handleClick={() => {
                    history.push(`${url}`)
                    goToStep('personSelect', true)
                  }}
                />
              </div>
            </div>
            <div className="hero__image">
              <img alt={`${personData.character_name}, Large Portrait`} src={personData.end_panel.image} />
            </div>
          </div>
        </div>
      </section>

      {personData.links ? (
        <section className="section--light">
          <div className="learnMore">
            <div className="learnMore__content">
              <div className="cardWrapper">
                {personData.links.data.map((linkData, index) => {
                  return <Card hasIcon={true} key={index} {...linkData} />
                })}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </GenericModal>
  )
}

export default EndModal
