import { useEffect, useState } from 'react'
import axios from 'axios'

const useFetch = (interactiveUrl) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  }

  useEffect(() => {
    const fetchData = () => {
      if (interactiveUrl) {
        try {
          setLoading(true)
          axios
            .get(interactiveUrl, {
              auth: {
                username: 'moar',
                password: 'SrwCHBxxw2Fr',
              },
            })
            .then((res) => {
              let data = res.data.data;
              if (typeof data.stories.data !== 'undefined') {
                data.stories.data = shuffle(data.stories.data);
              }
              setData(data);
            })
            .catch((err) => {
              console.log(err)
            })
        } catch (error) {
          setError(error)
        }
      } else {
        setError('no data offered')
      }

      setLoading(false)
    }
    fetchData()
  }, [interactiveUrl])
  return { error, loading, data }
}

export default useFetch
