import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'moar-interactive-shared'
import PeopleSlider from './../components/PeopleSlider'
import MobilePersonSelect from '../components/MobilePersonSelect'
import { revealPerson, hidePerson } from '../animations/selectAnimation'
import useInteractiveSlug from '../hooks/useInteractiveSlug'

const SelectPage = (props) => {
  const image = useRef()
  const title = useRef()
  const history = useHistory()
  const { url } = useInteractiveSlug()

  const { setPersonData, goToSlides, personData, stories, appMeta } = props

  // on first load set to first person
  const [selected, setSelected] = useState(personData)

  // we use this to disable fucntions until animations are complete
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    revealPerson(title, image, selected, () => setIsAnimating(false))
  }, [selected])

  // visually selecting a person on this page
  const selectNewPerson = (person) => {
    if (isAnimating) return
    setIsAnimating(true)
    hidePerson(title, image, selected, () => {
      setSelected(person)
    })
  }

  // this will set the person and load the correct slide data - then push you to the slides
  const setSelectedPerson = () => {
    setIsAnimating(true)
    setPersonData(selected)
    history.push(`${url}/${selected.name_id}`)
    goToSlides()
  }

  return (
    <div className="page page--select page--frame">
      <header className="mobileHeader">
        <div className="mobileHeader__inner">
          <h1>{appMeta.title}</h1>
        </div>
      </header>
      <div className="page__wrapper">
        <div className="panel">
          <div className="panel__inner">
            <header className="personSelect_header" ref={title}>
              <h1>{appMeta.title}</h1>
              <h2>{selected.description}</h2>
              <Button
                label={selected.cta}
                handleClick={() => {
                  if (isAnimating) return
                  setSelectedPerson()
                }}
              />
            </header>

            <div className="mobileSelect">
              {stories.map((person) => (
                <MobilePersonSelect
                  key={person.id}
                  person={person}
                  handleClick={() => {
                    if (isAnimating) return
                    setPersonData(person)
                    history.push(`${url}/${person.name_id}`)
                    goToSlides()
                  }}
                />
              ))}
            </div>
            <PeopleSlider
              selectNewPerson={selectNewPerson}
              people={stories}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
      </div>
      <div className="largePerson" ref={image}>
        {stories.map((person) => {
          return (
            <img
              key={person.id}
              className="largePerson__image"
              data-id={person.id}
              alt={`${person.character_name}, Large Portrait"`}
              src={person.feature_image}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SelectPage
