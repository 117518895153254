import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickOutside = (ref, handler) => {
    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            handler();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};

export default useClickOutside;
