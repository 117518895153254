import React from 'react'
import Cta from './Cta'

const Card = (props) => {
  const {
    ref,
    hasIcon,
    isExternal,
    title,
    cta_text,
    description,
    cta_link,
  } = props
  return (
    <div className="card">
      <div className="card__header">
        <h3>{title}</h3>
      </div>
      <div className="card__content">
        <p>{description}</p>
        <Cta
          href={cta_link}
          label={cta_text}
          ref={ref}
          icon={hasIcon}
          isExternal={isExternal}
        />
      </div>
    </div>
  )
}

export default Card
