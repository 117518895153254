import React from 'react';

const PrintIcon = () => {
    return (
        <svg aria-hidden={true} width="14px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6 4.44444H12.8V1.77778C12.8 0.795556 12.084 0 11.2 0H4.8C3.916 0 3.2 0.795556 3.2 1.77778V4.44444H2.4C1.072 4.44444 0 5.63556 0 7.11111V10.6667C0 11.6489 0.716 12.4444 1.6 12.4444H3.2V15.1111C3.2 15.6018 3.5584 16 4 16H12C12.4416 16 12.8 15.6018 12.8 15.1111V12.4444H14.4C15.284 12.4444 16 11.6489 16 10.6667V7.11111C16 5.63556 14.928 4.44444 13.6 4.44444ZM4.8 2.66667C4.8 2.176 5.1584 1.77778 5.6 1.77778H10.4C10.8416 1.77778 11.2 2.176 11.2 2.66667V4C11.2 4.24533 11.0208 4.44444 10.8 4.44444H5.2C4.9792 4.44444 4.8 4.24533 4.8 4V2.66667ZM11.2 11.1111V13.3333C11.2 13.824 10.8416 14.2222 10.4 14.2222H5.6C5.1584 14.2222 4.8 13.824 4.8 13.3333V11.1111C4.8 10.8658 4.9792 10.6667 5.2 10.6667H10.8C11.0208 10.6667 11.2 10.8658 11.2 11.1111ZM13.6 8C14.0416 8 14.4 7.60178 14.4 7.11111C14.4 6.62044 14.0416 6.22222 13.6 6.22222C13.1584 6.22222 12.8 6.62044 12.8 7.11111C12.8 7.60178 13.1584 8 13.6 8Z"
                fill="#1A1A1A"
            />
        </svg>
    );
}

export default PrintIcon;
